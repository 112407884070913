<template>
<div>
  <div id="chapter-tools">
    <div id="start-time">
      <v-btn text dense id="setStartTime">
        <v-icon @click="setStartTc" id="bookmark-icon"> mdi-bookmark-plus-outline</v-icon>
      </v-btn>
      <v-btn text dense id="timecode-wrapper" @click="skipToTime(startTc)" style="margin-top:5px;margin-bottom:5px;">
        <span id="timecode-preview" style="margin-top:5px;margin-bottom:5px;">{{ToSMPTE(startTc)}}</span>
      </v-btn>
    </div>
    <div id="thumbnail-time-wrapper">
      <div id="thumbnail-time">
        <v-btn dense @click="setThumbTc" text id="setPosterTime"><v-icon id="camera-icon">mdi-camera-plus</v-icon></v-btn>
        <img :src="img_preview" @click="skipToTime(thumbTc)" />
      </div>
    </div>
    <div id="title-input">
      <div id="chapter-title">
        <v-text-field dense autofocus solo flat outlined rounded hide-details
          v-model="title"
          placeholder="tittel"
          @keyup.enter="createChapter"
        >
          <v-icon slot="prepend" @click="reset" id="reset">mdi-refresh</v-icon>
          <v-icon slot="append" @click="createChapter" id="add-chapter">mdi-image-plus</v-icon>
        </v-text-field>
      </div>
      </div>
    </div>
</div>
</template>

<script>
// :style="`background-image: url('${img_preview}')`" // <!-- :src="img_preview" id="img-preview"/-->
import TC from 'node-timecodes'
import { ToSMPTE } from '@/helpers/utils'
const { detect } = require('detect-browser');

require('dseg/css/dseg.css')

export default {
  props: ['video'],
  data: () => ({
    title: '',
    editingTitle: false,
    thumbTc: -1,
    startTc: 0,
    frameRate: 25, // video.framerate
    mouseDown: false,
    currentTime: 0,
    intervalShuttle: null,
    intervalCleared: true,
    img_preview: require('@/assets/test-image-cropped.jpg'),
    showChromeAlert: false
  }),
  mounted () {
    this.$nextTick(function () {
      this.player.addEventListener('timeupdate', () => {
        this.currentTime = this.player.currentTime
      })
    })
  },
  created () {
  },
  computed: {
    browser () {
      return detect()
    },
    imageHeight () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return '38px';
        // case 'sm': return '400px'
        case 'md':
          return '16vw';
        default:
          return undefined
        // case 'lg': return '600px'
        // case 'xl': return '800px'
      }
    },
    player () {
      const player = document.getElementsByTagName('video')[0]
      return player
    },
    thumbTcSMTPE () {
      return TC.fromSeconds(0) // this.this.thumbTc < 0 ? TC.fromSeconds(0) : TC.fromSeconds(this.thumbTc)
    },
    startTcSMTPE () {
      return this.startTc ? TC.fromSeconds(this.startTc) : TC.fromSeconds(0)
    },
    currentTc () {
      return TC.fromSeconds(this.currentTime)
    },
  },
  methods: {
    // enableKeys () { this.$store.commit('enableKeys') },
    // disableKeys () {
    //   // console.log('disableKeys')
    //   this.$store.commit('disableKeys')
    // },
    getTime () {
      const player = document.getElementsByTagName('video')[0]
      const time = player.currentTime
      return time
    },
    ToSMPTE: ToSMPTE,
    toTimeCode (t) {
      return TC.fromSeconds(t)
    },
    async createChapter () {
      const videoId = this.$store.state.currentVideo.id
      if(this.title.length === 0) {
        this.title = 'tittlel mangler!'
      }

      const chapter = {
        title: this.title,
        start: this.startTc,
        posterTime: this.thumbTc,
        videoId: videoId
      }
      // console.log(JSON.stringify(chapter))
      let res
      try {
        res = await this.$store.dispatch('createChapter', chapter)
        console.log('res', res)
        this.reset()
      } catch (err) {
        alert('Kunne ikke opprette kapittel: ', err)
      }
    },
    skipToTime (t) {
      const player = document.getElementsByTagName('video')[0]
      player.currentTime = t
    },
    framesToSeconds (frames) {
      return frames / this.frameRate // this.video.framerate
    },
    setStartTc () {
      this.startTc = this.getTime()
      console.log('this.getTime()', this.getTime())
    },
    setThumbTc () {
      this.thumbTc = this.getTime()
      this.getImage()
    },
    reset () {
      const imagePreview = require('@/assets/test-image-cropped.jpg')
      console.log('imagePreview', imagePreview)
      this.title = ''; this.thumbTc = 0; this.startTc = 0; this.img_preview = require('@/assets/test-image-cropped.jpg')
    },
    getImage () {
      const video = document.getElementsByTagName('video')[0]

      const scale = 0.25
      const canvas = document.createElement('canvas')
      canvas.width = video.videoWidth * scale
      canvas.height = video.videoHeight * scale
      canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height)
      // .log('canvas', canvas)
      canvas.toDataURL()
      var img = document.createElement('img')
      img.src = canvas.toDataURL()
      this.img_preview = img.src
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';
@import url("https://fonts.googleapis.com/css?family=Questrial");

#chapter-tools {
  width: 100%;
  padding-top:3px;
  padding-bottom:3px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: strech;
  align-items: strech;
  grid-template-rows: 40px 40px;
  grid-row-gap: 5px;
  grid-template-areas:
    "start-time thumbnail-time"
    "title title";
  @media (min-width: $breakpoint-sm) {
    padding-top:3px;
    padding-bottom:3px;
    grid-template-columns: 220px 160px 1fr;
    grid-column-gap: 3px;
    grid-template-rows: 46px;
    grid-template-areas:
      "start-time thumbnail-time title";
  }
  // background:  map-get($grey, darken-4);
}
#start-time {
  background-color: var(--v-secondary-base);
  grid-area: start-time;
  display: grid;
  grid-template-columns: 30px auto;
  grid-column-gap: 8px;
}
#thumbnail-time-wrapper {
  width: 100%;
  background-color: var(--v-secondary-base);
  #thumbnail-time { /* CAMERA ICON and PREVIEW IMAGE */
    @media (min-width: $breakpoint-sm) {
      float: none;
      margin-top: 1px;
    }
    float: right;
    grid-area: thumbnail-time;
    display: grid;
    grid-template-columns: 66px 80px;
    grid-column-gap: 4px;
    #setPosterTime {
      #camera-icon {
        font-size: 18;
      }
      place-self: center;
    }
    img {
      margin-top:2px;
      height: 36px;
      border-radius: 7px;
      border: 2px solid rgb(24, 24, 24);
      &:hover {
        border: 2px solid rgb(95, 195, 253);
      }
      @media (min-width: $breakpoint-sm) {
        height: 40px;
      }
    }
  }
}


#setStartTime {
  place-self: center;
  #bookmark-icon {
    font-size: 28px;
    margin-left: 20px
  }
}
#timecode-wrapper {
  align-self: center;
  justify-self: center;
}
#timecode-preview {
  font-size: 20px;
  font-family: DSEG7-Modern;
  font-weight: 700;
  color: #FF2222;
}

#chapter-title {
  place-self: center stretch;
}
#title-input {
  padding-left: 10px;
  padding-right: 10px;
  height: 100%;
  grid-area: title;
  background-color: var(--v-secondary-base);
  display: grid;
  grid-template-columns: strech;
  align-items: strech;
  #reset {
    color: var(--v-warning-base);
    &:hover {
      color: var(--v-error-base);
    }
  }
  #add-chapter {
    color: var(--v-success-base);
    &:hover {
      color: var(--v-primary-base);
    }
  }
}


</style>
