<template>
  <div id="chapters">
    <!-- Player and chapter tools -->
    <div style="background:#333" id="player-and-tools" ref="playerAndTools">

      <!-- CHAPTER EDITING -->
      <div>
        <div v-if="!videoLoading" class="chapter-edit">
          <ChapterTools :video="video"/>
        </div>
        <div v-else class="chapter-edit">
          loading chapter tools
        </div>
      </div>

    </div>
    <!-- Scrolling Chapters -->

    <div id="scroll-area">
      <div :style="`height: ${scrollHeight - 168}px; overflow: auto;`">
        <resize-observer @notify="handleResize"></resize-observer>
        <Chapters :video="video" v-if="!videoLoading"/>
      </div>
    </div>
  </div>
</template>

<script>
// :style="`height: ${scrollHeight - 92}px;`
//  :style="`height: ${scrollHeight - 92}px;overflow: auto;`"
import Chapters from '@/components/chapters/ChapterList'
import ChapterTools from '@/components/chapters/chapterTools'
import { mapState } from 'vuex'
// const footerHeight = 48

export default {
  data () {
    return {
      video: {},
      errors: [],
      loading: true,
      saving: false,
      scrollHeight: false
    }
  },
  watch: {
    // call again the method if the route changes
    // '$route': 'getVideo'
  },
  components: {
    ChapterTools,
    Chapters
  },
  computed: {
    ...mapState(['playerKeysEnabled', 'playerPaused', 'videoLoading']),
    playerHeight () {
      return this.$store.state.playerHeight
    }
   },
  // created () {
  //   this.getVideo()
  // },
  mounted () {
    // this.scrollHeight = window.innerHeight - this.playerHeight
    this.handleResize ()
  },
  methods: {
    handleResize () {
      // console.log('resize')
      const playerAndToolsHeight = this.$refs.playerAndTools.clientHeight
      this.scrollHeight = window.innerHeight - this.playerHeight - playerAndToolsHeight
    },
    // async getVideo () {
    //   const videoId = this.$route.params.videoId
    //   // await store.dispatch('setCurrentVideo', videoId)
    // }
  }
}
</script>


<style lang="scss" scoped>
  @import '@/styles/variables';
  #player-and-tools {
    // height: 100%;
  }
  // @import '@/styles/variables';
  #player-wrapper {

    @media (min-width: $breakpoint-sm) {
      background: red;
      // grid-template-columns
    }
  }
  #scroll-area {
     position: relative;
    // overflow: auto;
    // height: 200px;
  }
</style>
