<template>
  <div id="chapter">
    <div class="chapter-image-area">
        <img @click="skipToTime(chapter.start)" :src="imageUrl"/>
        <!-- <v-icon color="white" size="18px" class="delete-button"> mdi-sticker-remove-outline </v-icon> -->
        <v-btn small outlined text @click="removeChapter" class="delete-button">
          <v-tooltip bottom open-delay="1000">
            Sletter dette kapittelet
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="white" id="remove-icon" v-bind="attrs" v-on="on"> mdi-sticker-remove-outline </v-icon>
            </template>
          </v-tooltip>
        </v-btn>

        <v-btn small outlined text @click="setPosterTime" class="camera">
          <v-tooltip top open-delay="1000">
            Bytter dette kapittelets poster bilde med det som vises i spilleren nå
            <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="white" id="camera-icon">
                  mdi-camera-party-mode
                </v-icon>
            </template>
          </v-tooltip>
        </v-btn>


        <v-btn small outlined text @click="setStartTime" class="time-button">
          <v-tooltip top open-delay="1000">
            Flytter dette kapittelets start-tid til videospillers nåværende tid
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="white" id="time-icon" v-bind="attrs" v-on="on">mdi-av-timer</v-icon>
            </template>
          </v-tooltip>
        </v-btn>

        <div class="start-time thin-paragraph">{{startTime}}</div>
    </div>

    <div class="chapter-title-area">
      <v-text-field
        class="chapter-title"
        :class="editingTitle && 'chapter-title-active'"
        ref="chapterTitle"
        height="40"
        @focus="editTitle"
        @blur="updateTitle"
        @keyup.enter="updateTitle"
        v-model="chapterTitle"
        hide-details solo flat dense
      >
      </v-text-field>
    </div>

  </div>
</template>

<script>

import { thumb } from '@/helpers/utils.js'
import TC from 'node-timecodes'
require('dseg/css/dseg.css')

export default {
  props: ['chapter'],
  data () {
    return {
      editingTitle: false,
      chapterTitleEdited: '',
    }
  },
  mounted () {
    this.chapterTitleEdited = this.chapter.title
  },
  computed: {
    imageUrl () {
      return thumb(this.video.fileName, this.chapter.posterTime, 300)
    },
    startTime () {
      return TC.fromSeconds(this.chapter.start)
    },
    posterTime () {
      return TC.fromSeconds(this.chapter.posterTime)
    },
    player () {
      const player = document.getElementsByTagName('video')[0]
      return player
    },
    video () {
      return this.$store.getters.getCurrentVideo
    },
    chapterTitle: {
      get () {
        if (this.chapter) return this.chapter.title
        return ''
      },
      set (title) {
        this.chapterTitleEdited = title
      }
    }
  },
  methods: {
    removeChapter () {
      this.$store.dispatch('removeChapter', this.chapter.id)
    },
    editTitle () {
      console.log('editing title')
      this.editingTitle = true
      // setTimeout( () => {
      //   this.$refs.chapterTitle.focus()
      // }, 100)
    },
    async updateTitle () {
      this.$refs.chapterTitle.blur()
      this.chapter.title = this.chapterTitleEdited
      const chapter = this.chapter
      await this.$store.dispatch('updateChapter', { chapter, sort: false } )
      this.editingTitle = false
    },
    skipToTime () {
      this.$store.state.player.currentTime = this.chapter.posterTime
    },
    getTime () {
      return this.player.currentTime
    },

    async setStartTime () {
      this.chapter.start = this.getTime()
      const chapter = this.chapter
      this.$store.dispatch('updateChapter', { chapter, sort: true })
    },
    async setPosterTime () {
      this.chapter.posterTime = this.getTime()
      const chapter = this.chapter
      this.$store.dispatch('updateChapter', { chapter, sort: false})
    },
    thumb (fileName, time, width) {
      return thumb(fileName, time, width)
    },

  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';
/*
  @media (min-width: 350px) {
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  }
  @media (min-width: 430px) {
    grid-template-columns: repeat(auto-fit, minmax(135px, 1fr));
  }
  @media (min-width: $breakpoint-sm) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
  @media (min-width: $breakpoint-lg) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
  */
#chapter {
  display: grid;
  grid-template-rows: auto auto 1fr 1fr;
  max-width: 400px;
}

.chapter-image-area {
  position:relative;
}

.camera {
  position: absolute;
  bottom: 12px;
  right: 4px;
}

.time-button {
  position: absolute;
  bottom: 11px;
  left: 2px;
}

.delete-button {
  position: absolute;
  top: 4px;
  left: 2px;
  i: {
    size: 40px;
  }
}

.start-time {
  padding: 2px;
  background: rgba(0,0,0,0.5);
  position: absolute;
  top: 9px;
  right:3px;
  font-size: 11px;
  font-family: DSEG7-Modern;
  font-weight: 700;
  color: #FF2222;
  @media (min-width: $breakpoint-sm) {
    font-size: 14px;
    top: 6px;
    right:8px;
  }
}

.chapter-title-area {
  // height: 40px;
  position: relative;
}
.chapter-title {
  //font-size: 12px

  padding: 2px;
}
.chapter-title-active {
  background: rgba(220, 0, 0, 0.3);
}
.chapter-delete {
  position: absolute;
  right: 0px;
  top: 2px;
}
img{
  width: 100%;
}
</style>
