<template>
  <div>
    <div v-if="chapters" id="chapters">
      <Chapter
        v-for="(chapter, index) in chapters"
        :key="index"
        :chapter = chapter
      />
    </div>
    <div v-else>
      loading chapters
    </div>
  </div>
</template>
<!--         v-on:listChapters="list" -->
<script>

import Chapter from './ChapterListChapter'

export default {
  data () {
    return {
      // chapters: undefined
    }
  },
  components: {
    Chapter
  },
  computed: {
    chapters () {
      console.log('getting chapters')
      const chapters = this.$store.getters.getChapters
      // if (chapters) console.log(JSON.stringify(chapters.map( c => {return c.title})))
      if (!chapters) return false
      return chapters
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';
#chapters {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
  grid-gap: 0.5%;
  @media (min-width: 350px) {
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  }
  @media (min-width: 430px) {
    grid-template-columns: repeat(auto-fit, minmax(135px, 1fr));
  }
  @media (min-width: $breakpoint-sm) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
  @media (min-width: $breakpoint-lg) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}
#chapter-loading {
  height: calc(100vh - (100vw - 310px) * 9/16 - 70px);
  width: calc(100vw - 310px);
}
</style>
